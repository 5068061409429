import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";

import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";
import "./css/app2.css";
import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import Gamehistory from "./uiComponents/Gamehistory";
import "animate.css";
import axios from "axios";
import { IoChatbubblesOutline } from "react-icons/io5";
import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";

// import Games from './uiComponents/Games';
// import Kyc from './uiComponents/Kyc';
import Kyc2 from "./uiComponents/Kyc2";
// import kyc3 from './uiComponents/kyc3';
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";

import Rightcontainer from "./Components/Rightcontainer";
import TopDownload from "./Components/TopDownload";
import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import Header from "./Components/Header";
import $ from "jquery";
import HomeNew from "./uiComponents/HomeNew";

const App2 = () => {
  //const [mount, setMount] = useState(true)
  //const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const [setting, setSetting] = useState({
    whatsup_1: "9079877696",
    whatsup_2: "9079877696",
    whatsup_3: "9079877696",
  });
  const [showAll, setShowAll] = useState(false);
  const getUser = async () => {
    if (access_token) {
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .get(baseUrl + `me`, { headers })
        .then((res) => {
          setUser(res.data);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            // history.pushState("/login")
          }
        });
    }
    axios
      .get(baseUrl + `setting`)
      .then((res) => {
        setSetting(res.data);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    // $('.whatsupImg').tooltip();
    getUser();

    // eslint-disable-next-line
  }, []);
  const handleClick = () => {
    const beckendLiveUrl = process.env.REACT_APP_BACKEND_LIVE_URL;
    const access_token = localStorage.getItem("token");
    const url = beckendLiveUrl + `support?token=${access_token}`;
    window.open(url, "_blank", "noreferrer");

    // setShowAll(!showAll);
  };

  return (
    <>
      {!access_token && (
        <div className="leftContainer">
          <div>
            <Header user={user} />
          </div>
        </div>
      )}

      {!access_token ? (
        <Switch>
          <Route exact path="/" component={HomeNew} />
          <Route path="/landing" component={HomeNew} />
          <Route path="/login" component={userLogin} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route path="/about" component={AboutUs} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/contact-us" component={Support} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Redirect to="/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/transaction-history" component={Transactionhistory} />
          {/* <Route exact path="/transaction" component={Transactionhistory} /> */}
          <Route exact path="/Referral-history" component={Referralhis} />
          <Route exact path="/landing" component={Landing} />
          {/* <Route exact path="/adminlogin" component={Login} /> */}
          <Route exact path="/Gamehistory" component={Gamehistory} />
          {/* <Route exact path="/profile" component={Profile} /> */}
          <Route
            exact
            path="/HomePage/:Game"
            render={() => <Homepage walletUpdate={getUser} />}
          />
          <Route exact path="/refer" component={Refer} />
          <Route exact path="/Notification" component={Notification} />
          <Route exact path="/" component={Landing} />
          <Route path="/profile" component={Profile1} />
          <Route path="/viewgame1/:id" component={ViewGame1} />
          <Route
            path="/addcase"
            render={() => <Addcase walletUpdate={getUser} />}
          />

          <Route
            path="/Withdrawopt"
            render={() => <Withdrawopt walletUpdate={getUser} />}
          />
          <Route path="/wallet" component={Mywallet} />
          <Route path="/support" component={Support} />

          {/* <Route path="/Games" component={Games} /> */}
          <Route exact path="/landing/:id" component={Landing} />
          <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
          <Route path="/Rules" component={Gamerules} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          {/* <Route path="/timer" component={Timer}/> */}
          <Route
            path="/return"
            render={() => <Return walletUpdate={getUser} />}
          />
          <Route path="/notify" component={Notify} />

          <Route
            path="/Redeem"
            render={() => <Redeem walletUpdate={getUser} />}
          />
          <Route path="/contact-us" component={Support} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Route path="/about" component={AboutUs} />

          <Redirect to="/landing" />
        </Switch>
      )}
      <Rightcontainer />

      {!showAll ? (
        <div
          className="float whatsupImg"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          title="Withdrawal and Deposit"
        >
          <IoChatbubblesOutline />
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            bottom: "70px",
            right: "20px",
            zIndex: "1000",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <a
            href={`https://api.whatsapp.com/send?phone=91${setting.whatsup_1}&text=Hello`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "#25d366",
              color: "white",
              textDecoration: "none",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "14px",
            }}
            title="Game Result (Win/Lost)"
          >
            Game Result (Win/Lost)
          </a>
          <a
            href={`https://api.whatsapp.com/send?phone=91${setting.whatsup_2}&text=Hello`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "#25d366",
              color: "white",
              textDecoration: "none",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "14px",
            }}
            title="Withdrawal and Deposit"
          >
            Withdrawal and Deposit
          </a>
          <a
            href={`https://api.whatsapp.com/send?phone=91${setting.whatsup_3}&text=Hello`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "#25d366",
              color: "white",
              textDecoration: "none",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "14px",
            }}
            title="Other Problems"
          >
            Other Problems
          </a>
        </div>
      )}
    </>
  );
};
export default App2;
