import React, { useEffect, useState } from 'react'
import css from "../css/Refer.module.css"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2';
import Header from '../Components/Header';
import '../Components/Component-css/Common.css';
// import 'remixicon/fonts/remixicon.css'




const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState()
  const Cashheader = () => {
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data)
        // console.log(res.data);
        Allgames(res.data.referral_code)
      }).catch((e) => {
        alert(e.msg)
      })

  }
  
  const [cardData, setGame] = useState([])

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data)
console.log(res.data)

      })

  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
    const fetchData = async () => {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      return setWebsiteSettings(data);
    }

  useEffect(() => {
    Cashheader()
    fetchData();
//eslint-disable-next-line
  }, [])
  
  
    const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: 'center',
      icon: 'success',
      type: 'success',
      title: 'Refer Code Copied',
      showConfirmButton: false,
      timer: 1200,
    

    });

  }

  if (user === undefined) {
    return null
  }

  return (
      <>
          <Header user={user} />


    <div>
      <div className='leftContainer' style={{height:'100vh'}}>
     
        <div className={`${css.center_xy}`}>
          <picture>
            <img alt="img" width="100px" src={process.env.PUBLIC_URL + 'Images/refer/refer.gif'} className="snip-img" />
          </picture>
          <div>
            <div className=''>
              <div className="earn_now_title">
                Earn now Unlimited
                <span aria-label="party-face" >
                  🥳
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-center earn_now_caption">
              Refer your friends now!
            </div>


            <div className='earned_box_container'>
            
            <div className="current_earning">
              Current Earning:
              <b>
                {user.referral_wallet}
              </b>
              <Link className="earning_redeem" to="/Redeem">
                Redeem
              </Link>
            </div>


            <div className="total_earning">
              Total Earned:
              <b>
                {user.referral_earning}
              </b>
            </div>
            </div>


      


            {/* <div className={`${css.progress}`}>
              <div className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`} aria-valuenow={user.referral_earning} aria-valuemax={10000} style={{ width: `${user.referral_earning * 100 / 10000}%` }}>
              </div>
            </div> */}
            {/* <div className="progress_bar_title">
              <span >
                Max: ₹10,000
              </span>
              <Link className="float-right" to="/update-pan">
                Upgrade Limit
              </Link>
            </div> */}


           
          </div>
        </div>

            <div className='refer_code_section'>

             <div className={`refferal_code text-center`}>
              Your Refer Code: {user.referral_code}
              


              <i className="fa-solid fa-paste" onClick={(e) => copyCode(e)}></i>
            </div>
            
              <div className="d-flex justify-content-center total_refers">
              Total Refers:&nbsp;
              <b>
               {cardData&&cardData}
              </b>
            </div> 

            
        <div className='refer_whatsapp_btn'>
          <a href={`whatsapp://send?text=Play Ludo and earn ₹10000 daily.  ${(WebSitesettings) ? WebSitesettings.CompanyWebsite : ''}login/${user.referral_code}  Register Now, My refer code is ${user.referral_code}.`}>
          <button className="">
          <i className="fa-brands fa-whatsapp"></i> Share          
            </button>
          </a>
        </div>

            </div> 



        <div className='refer_rules_container'>
          <div className="refer_earn_rules_title">
            Refer &amp; Earn Rules
          </div>
                <div className='refer_earn_content'>
                When your friend signs up on Our website or App from your referral link.
              </div>

          <div className="align-items-center">            
            {/* <picture className='referral_content_img'>
              <img alt="img" width="200px" src={process.env.PUBLIC_URL + 'Images/refer/giftbanner.png'} />
            </picture> */}
            <div>
              <h3 className='referral_wining_content'>
                  You get <strong>
                    2 % Commission </strong>
                  on your <strong>
                    referral's winnings.
                  </strong>
                </h3>
            </div>
          </div>


          <div className="align-items-center">
            {/* <picture className='referral_content_img'>
              <img alt="img" width="200px" src={process.env.PUBLIC_URL + 'Images/refer/banner.png'} className="snip-img" />
            </picture> */}
            <div className='referral_wining_content'>
              <div className='referral_notification'>
                Suppose your referral plays a battle for ₹10000 Cash,
              </div>
              <div style={{fontSize: "1.5rem", color: 'yellow'}}>
                You get <strong>
                  ₹200 Cash
                </strong>
                <strong>
                </strong>
              </div>
            </div>
          </div>
        </div>



      </div>

    </div>
    </>
  )
}





export default Refer
