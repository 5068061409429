// import { width } from '@mui/system';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import { UserFooterBar } from "../uiComponents/UserFooterBar";
import "../Components/Component-css/Common.css";
import { IoLogOutSharp } from "react-icons/io5";
const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const publicLocalUrl = process.env.REACT_APP_BACKEND_LOCAL_URL;
  const publicLiveUrl = process.env.REACT_APP_BACKEND_LIVE_URL;
  var publicUrl;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
    publicUrl = publicLocalUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
    publicUrl = publicLiveUrl;
  }

  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(false);

  const history = useHistory();
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setError(false);
    return setWebsiteSettings(data);
  };
  document.title = WebSitesettings
    ? WebSitesettings.WebTitle
    : "Skill based game";
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  }, []);

  const handleImageError = () => {
    setError(true);
    setImageSrc(publicUrl + WebSitesettings.SmallLogo);
  };

  // logout user
  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  return (
    <div className="">
      {access_token ? (
        <React.Fragment>
          <div id="sidebarOverlay" onClick={w3_close}></div>
          <div
            className="w3-sidebar w3-bar-block w3-sidebar-text"
            id="mySidebar"
            style={{ paddingBottom: "70px" }}
          >
            <div className="close_nav">
              <i className="fa-regular fa-circle-xmark" onClick={w3_close}></i>
            </div>
            <Link
              to={"/Profile"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                {user && user.avatar ? (
                  <img
                    width="30px"
                    height="30px"
                    src={publicUrl + `${user && user.avatar}`}
                    alt="profile"
                    style={{ borderRadius: "50px" }}
                  />
                ) : (
                  <img
                    src="/Images/Header/user.png"
                    width="28px"
                    alt="profile"
                  />
                )}
              </picture>

              <div style={{ marginLeft: ".5rem" }}>My Profile</div>
            </Link>

            <Link
              to={"/landing"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/gamepad.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Win cash</div>
            </Link>
            <Link
              to={"/wallet"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/wallet.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>My wallet</div>
            </Link>

            <Link
              to={"/Gamehistory"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/gamesHistory.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Game History</div>
            </Link>

            <Link
              to="/transaction-history"
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/order-history.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Transaction History</div>
            </Link>

            <Link
              to={"/refer"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/sreferEarn.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Refer and Earn</div>
            </Link>

            <Link
              to={"/Referral-history"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/refer-history.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Refer History</div>
            </Link>

            <Link
              to={"/Notification"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/notifications.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Notification</div>
            </Link>

            <Link
              to={"/support"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/support.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Support</div>
            </Link>
            <Link
              to={"/term-condition"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/terms.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Term & Conditions</div>
            </Link>

            <Link
              to="/login"
              className={`${css.center_xy} color: '#ffffff' w3-bar-item w3-button `}
              onClick={(e) => logout(e)}
              style={{
                fontSize: "1.2em",
                color: "#ffffff",
                marginBottom: "3rem",
                textTransform: "uppercase",
              }}
            >
              {/* <span className='black'> <MdOutlineLogin/> Log Out  </span> */}
              <picture className="icon">
                <IoLogOutSharp />
              </picture>
              <div style={{ marginLeft: ".5rem", fontSize: "14px" }}>
                Log Out
              </div>
            </Link>

            {/* <Link className="w3-bar-item w3-button" to="!/" onClick={(e) => logout(e)}>
          <picture className="icon">
            <img alt="img" src={process.env.PUBLIC_URL + '/Images/logout.png'} />
          </picture>
          <div style={{ marginLeft: '.5rem' }}>
            Logout
          </div>

        </Link> */}
          </div>
          {/* w3-button w3-teal w3-xlarge */}

          <div className="w3-teal">
            <div className="w3-container ">
              <div className={`${css.headerContainer} `}>
                <div className="row">
                  <div
                    className="col-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <button
                      className=" float-left top_nav_btn"
                      onClick={w3_open}
                      id="hambergar"
                      style={{ color: "#d0e1f9" }}
                    >
                      {/* <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src={process.env.PUBLIC_URL + '/Images/LandingPage_img/sidebar.png'}
                      className="snip-img"
                      alt=''
                    />
                  </picture> */}
                      <i
                        className="fa-solid fa-bars"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                    </button>
                  </div>

                  <div
                    className="col-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link to="/">
                      <picture
                        className={`ml-2 ${css.navLogo} d-flex download_bar_logo`}
                      >
                        {error ? (
                          <img
                            src={publicUrl + WebSitesettings.SmallLogo}
                            alt="Logo"
                            className="snip-img img-fluid"
                          />
                        ) : (
                          <img
                            src={publicUrl + WebSitesettings.SmallLogo}
                            className="snip-img img-fluid"
                            alt="Logo"
                            onError={handleImageError}
                          />
                        )}
                      </picture>
                    </Link>
                  </div>

                  <div
                    className="col-8"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={`${css.menu_items}`}>
                      <Link className={`${css.box}`} to="/Addcase">
                        <div className={`${css.moneyBox_header}`}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/global-rupeeIcon.gif"
                            }
                            alt=""
                            width="24px"
                          />
                        </div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.Wallet_balance !== undefined
                            ? user.Wallet_balance
                            : 0}
                        </div>
                        <picture className={`${css.walletAdd}`}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/addSign.png"
                            }
                            className="snip-img"
                          />
                        </picture>
                      </Link>
                      <div className="subscribe-btn">
                        <a
                          className="subscribe-link"
                          href={process.env.REACT_APP_YOUTUBE_LINK}
                        >
                          Subscribe
                        </a>
                      </div>

                      {/* <a
                        className=""
                        href={process.env.REACT_APP_YOUTUBE_LINK}
                        style={{ marginLeft: "0.5rem" }}
                      >

                        <div className="newyoutube">
                          <IoPlayCircleOutline />
                        </div>
                      </a> */}
                    </div>
                  </div>
                </div>

                {/* <span className="mx-5"></span>
          
                <span className="mx-5"></span> */}
              </div>
            </div>
          </div>
          <div id="userFooterBar">
            <UserFooterBar />
          </div>
        </React.Fragment>
      ) : (
        <div className="w3-teal">
          <div className="w3-container ">
            <div
              className={`${css.headerContainer} desktopheader-newhome justify-content-between`}
            >
              <Link to="/" style={{ width: "30%" }}>
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  {error ? (
                    <img
                      src={publicUrl + WebSitesettings.SmallLogo}
                      alt="Logo"
                      className="snip-img img-fluid navLogo_logo2"
                    />
                  ) : (
                    <img
                      src={publicUrl + WebSitesettings.SmallLogo}
                      className="snip-img img-fluid navLogo_logo2"
                      alt="Logo"
                      onError={handleImageError}
                    />
                  )}
                </picture>
              </Link>
              <div className={`ml-5`}>
                <Link type="button" className="login_button" to="/login">
                  LOGIN
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
