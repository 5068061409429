import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App2 from './app2.js/App2';
import './app/App.scss';


ReactDOM.render(

  <BrowserRouter>

    <App2 />
    {/* <Ludoempire/> */}

  </BrowserRouter >
  , document.getElementById('root'));
  