import React, { memo } from 'react'
import css from "../Modulecss/Home.module.css";
// import css from "../Components/Component-css/Common.css";
import { Link } from "react-router-dom";
import acceptSound from "./accept.mp3";
import findGif from "../css/loading_old.gif";
import playSound from "./play.mp3";
import { nodeName } from 'jquery';
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {   
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const BetCard = React.memo(({ allgame, user, deleteChallenge, getPost, RejectGame, winnAmount, AcceptChallang, updateChallenge }) => {
  
  // if(user == allgame.creator._id && allgame.Status == "new"){
  //   setTimeout(() => {
  //     alert('bettle timeout');
  //     deleteChallenge(allgame._id);
  //   }, 60000);
  // }    

  console.log('allgame',allgame);
  
  return (<div className={`${css.betCard} mt-2`}>
    
        <span
          className={`${css.betCardTitle} d-flex text-uppercase ${css.betTitleDiv}`}
        >
          CHALLENGE FROM
          <span className="ml-2" style={{ color: "#ffee00" }}>
            {allgame.creator.Name}
          </span>
          {user == allgame.creator._id &&
            allgame.Status == "new" && (
              <button
                className={`${css.betTitleDivBtn}  p-1 m-1 mb-1 ml-auto`}
                onClick={() => deleteChallenge(allgame._id)} 
                style={{backgroundColor:'var( --light-blue-color)', border:'none', padding: '0.5rem', 
                display:'block', borderRadius: '0.2rem'}}

              >
                DELETE
              </button>
            )}

          {user == allgame.creator._id &&
            allgame.Status == "requested" && (<div className="d-flex ml-auto align-items-center" >

                <Link to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}  onClick={(e) => getPost(allgame._id)} style={{ bottom: '0' }}>
              <button 
                className={`${css.start_btn}`}
              
              >
                START
              </button>
              </Link>
              <button
                className={`${css.reject_btn}`}
                
                onClick={() => RejectGame(allgame._id)} style={{ bottom: '0' }}
              >
                REJECT
              </button>
            </div>
            )}
        </span>





    <div className={`d-flex ${css.betBodyDiv}`}>
   
      <div className="entry_fee">
        <span className={css.betCardSubTitle}>Entry Fee</span>
        <div>
          <img
            src={
              process.env.PUBLIC_URL +
              "/Images/LandingPage_img/global-rupeeIcon.gif"
            }
            alt=""
            width="21px"
          />
          <span className={css.betCardAmount}>
            {allgame.Game_Ammount}
          </span>
        </div>
      </div>

      <div >
        <span className={css.betCardSubTitle2}>Prize</span>
        <div>
          <img
            src={
              process.env.PUBLIC_URL +
              "/Images/LandingPage_img/global-rupeeIcon.gif"
            }
            alt=""
            width="21px"
            style={{marginLeft:'1.5rem'}}
          />
          <span className={css.betCardAmount2}>
            {allgame.Game_Ammount +
              winnAmount(allgame.Game_Ammount)}
          </span>
        </div>
      </div>
      {user !== allgame.creator._id &&
        allgame.Status == "new" && (
          <button
            className={`${css.play_btn}`}
            onClick={() => AcceptChallang(allgame._id)}
           
          >
            Play
          </button>
        )}
      {/* {user == allgame.accepted_by._id && allgame.Status == 'running' && <button className={`${css.bgSecondary} ${css.playButton} ${css.cxy}`} >start</button>} */}
      {user == allgame.creator._id &&
        allgame.Status == "new" && (
          <div className="text-center col-5 ml-auto mt-auto mb-auto">
            <div className="pl-2 text-center">
                <img
                  src={findGif}
                  style={{ width: "42px", height: "42px" }}
                />
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                Finding Player!
              </span>
            </div>
          </div>
        )}

      {user !== allgame.creator._id &&
        allgame.Status == "requested" && (
          <div className="flex_column">
            
            <div
              className={`${css.requested_btn}`}
            >
              Requested
            </div>
           
            <button
              className={`${css.cancel_btn}`}
              onClick={() => RejectGame(allgame._id)}
            >
             Cancel
            </button>
          </div>
        )}


      {user !== allgame.creator._id &&
        allgame.Status == "running" && (

          <div className="d-flex ml-auto align-items-center">
            <audio src={playSound} autoPlay>
            </audio>
            <Link
              className={`${css.opponent_start}`}
              to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
              onClick={(e) => updateChallenge(allgame._id)}
            >
              start
            </Link>
          </div>
        )}
      {user == allgame.creator._id &&
        allgame.Status == "requested" && (
          <div className="d-flex ml-auto align-items-center mr-5 mt-1">
            <audio src={acceptSound} autoPlay>
            </audio>
           
            <div className="text-center col">
              <div className="pl-2">
                {allgame.accepted_by.avatar ? (<img
                  src={baseUrl+`${allgame.accepted_by.avatar}`}
                  alt=""
                  width='40px' height="40px"
                  style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%", marginTop: "5px" }}
                />) : (<img
                  src={`https://realkd.in/user.png`}
                  alt=""
                  width='40px' height="40px"
                  style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%", marginTop: "5px" }}
                />)}
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className={css.betCard_playerName}>
                  {allgame.accepted_by.Name}
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  </div>)
})

export default memo(BetCard);