import React, { useEffect} from 'react'
import { useLocation } from 'react-router-dom';
const HomeNew = () => {
    const location = useLocation();
    console.log("location: ", location);
    const loadCSS = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;
        document.head.appendChild(link);
    }

    const unloadCss = (href) => {
        const links = document.querySelectorAll(`link[href="${href}"]`);
        links.forEach(link => link.parentNode.removeChild(link));
    }

     useEffect(() => {
        const homeNew = `${process.env.PUBLIC_URL}/HomeNew.css`;
        loadCSS(homeNew);
        return () => {
            unloadCss(homeNew)
        }
   },[location]);
    return (
        <div className='ludomain-empire'>
            <div className='ludo-container'>
                <div className=' play'>
                    <div className='pc1'>
                        <div className='banner-one__content'>
                            <h1 className='stol-400'>
                                <div className='text-extra-large'>Play Ludo & Win Real Money</div>
                                <div className='yellowgradient'>
                                    Up to

                                    <span>₹1 Crore</span>

                                    Daily!
                                </div>
                            </h1>
                            <div className='certificate'>
                                <img src={process.env.PUBLIC_URL + '/homenew/certificate.webp'} alt="xyz" />
                            </div>
                        </div>

                    </div>
                    <div className='pc2'><img src={process.env.PUBLIC_URL + '/homenew/banner-image.webp'} alt="" /></div>
                </div>
            </div>

            <div className='safe-legal'>
                <div className=' safe-container'>
                    <div className='newclass'>
                        <div className='safe-flex'>
                            <div><img src={process.env.PUBLIC_URL + '/homenew/safe.webp'} alt="" /></div>
                            <div className='safe-flexk-andar'><p className='para'> 100% <br />
                                Safe & Legal </p></div>
                        </div>

                        <div className="hr"></div>
                        <div className='safe-flex'>
                            <div><img src={process.env.PUBLIC_URL + '/homenew/withdrawls.webp'} alt="" /></div>
                            <div className='safe-flexk-andar'><p className='para'> Instant<br />
                                Withdrawals  </p></div>
                        </div>
                        <div className="hr"></div>
                    </div>

                    <div className='newclass'>
                        <div className='safe-flex'>
                            <div><img src={process.env.PUBLIC_URL + '/homenew/games.webp'} alt="" /></div>
                            <div className='safe-flexk-andar'><p className='para'>Multi <br />
                                Games  </p></div>
                        </div>
                        <div className="hr"></div>
                        <div className='safe-flex'>
                            <div><img src={process.env.PUBLIC_URL + '/homenew/winners.webp'} alt="" /></div>
                            <div className='safe-flexk-andar'><p className='para'> 8 Lakh+ <br />
                                Daily Winners  </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNew
