import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import "../css/kyc.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import css from '../css/Pan.module.css'
import Swal from "sweetalert2";
import '../css/Loader.css'
import '../Components/Component-css/Common.css';


const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  

const AadharVerificationForm = () => {
  const [verificationOtpSent, setVerificationOtpSent] = useState(false);
  const [aadharNumber, setAadharNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [clientId, setClientId] = useState('');
  const [verificationStatus, setVerificationStatus] = useState(false);
  
  const [process, setProcess] = useState(false);
  
  const handleAadharNumberChange = (e) => {
    setAadharNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

const history = useHistory()

  const handleVerify = async () => {
    try{
        setProcess(true);
        const access_token = localStorage.getItem('token')
          const headers = {
            Authorization: `Bearer ${access_token}`,
          }
            const aadharNumbers = {'aadharNumber':aadharNumber}; 
          axios.post(baseUrl+`aadhar/aadharverification`, aadharNumbers, { headers })
            .then((res) => {
              console.log(res.data.data);
                setProcess(false)              
              if (res.data.statuscode == 200) {

                  setVerificationOtpSent(true);
                  
                  setClientId(res.data.data.client_id)
                   Swal.fire({
                  title: "Aadhar Verification OTP Sent to Your Registred Mobile Number",
                  icon: "success",
                  confirmButtonText: "ok",
                });
                
              }
              else {
                  setProcess(false)
                setVerificationOtpSent(false);
               Swal.fire({
                  title: "Invalid Aadhar Number or Registred Mobile number!",
                  icon: "danger",
                  confirmButtonText: "error",
                });
              }
              // console.log(res.data)
              //
            }).catch((e) => {
                setProcess(false)
            setVerificationOtpSent(false);
              console.log(e);
              if (e.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('token');
                window.location.reload()
              }
              
              if (e.response.status == 400) {
                Swal.fire({
                  title: "Kyc Already Verified",
                  icon: "success",
                  confirmButtonText: "ok",
                });
              }
              
              
            });
            
    
    } catch (error) {
        setProcess(false)
      console.error('Error verifying OTP:', error);
      setVerificationOtpSent(false);
    }
  };
  
  
  const handleVerifyOTP = async () => {
    try{
        setProcess(true);
        const access_token = localStorage.getItem('token')
          const headers = {
            Authorization: `Bearer ${access_token}`,
          }
          let formData = new FormData(); 
            formData.append('otpnumber', otp);   //append the values with key, value pair
            formData.append('client_id', clientId);
            
          axios.post(baseUrl+`aadhar/otpverification`, formData, { headers })
            .then((res) => {
                setProcess(false)
            //   console.log(res.data.data);
            if (res.data.statuscode == 200) {
                setVerificationStatus(true);
                 Swal.fire({
                  title: "Aadhar Verification Completed",
                  icon: "success",
                  confirmButtonText: "ok",
                });
                history.push("/Profile");
              }
              else {
                  setProcess(false)
                  setVerificationStatus(false);
               Swal.fire({
                  title: "Aadhar Verification Failed, Please check your details",
                  icon: "danger",
                  confirmButtonText: "error",
                });
              }
              // console.log(res.data)
              //
            }).catch((e) => {
                setProcess(false)
                setVerificationStatus(false);
              console.log(e);
              if (e.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('token');
                window.location.reload()
              }
            });
            
    
    } catch (error) {
        setProcess(false)
      console.error('Error verifying OTP:', error);
      setVerificationStatus(false);
    }
  };

  return (
    <div className="kyc-doc-input">
    {!verificationStatus &&
      <h3 style={{textAlign: 'center', marginBottom: '3rem'}}>Aadhar Number Verification</h3>
    }
    
    {verificationStatus &&
      <div className='profile_sub_box w-100'>
            <h2>AadharKYC Verification Successfully Completed.</h2>
      <Link className={`profile_wallet`} to="/Profile">
        <button className="w-100" style={{
              border: 'none', borderRadius: '5px',
              fontSize: '1em',
              fontWeight: '700',
              height: '48px',
              textTransform: 'uppercase',
              backgroundColor: '#ffffff',
              color: '#000051'
            }}>
            My Profile
        </button>
    </Link>
    </div>
    }
    
      <form>
      {!verificationStatus &&
      <div className="kyc-doc-input">
          <div className="label">Enter Aadhar Number</div>
          <input
            type="text"
            placeholder="Enter Aadhar Number"
            value={aadharNumber}
            onChange={handleAadharNumberChange}
          />
        </div>
        }
         <br />
        {!verificationStatus && verificationOtpSent &&
        <div className="kyc-doc-input">
          <div className="label">Enter OTP</div>
           <input
            type="text"
            value={otp}
            onChange={handleOTPChange}
            placeholder='Enter 6 Ditit OTP'
          />
        </div>
        
        }
        <br />
        {!verificationStatus && !verificationOtpSent &&
        <button type="button" onClick={handleVerify} className="w-100" style={{
              border: 'none', borderRadius: '5px',
              fontSize: '1em',
              fontWeight: '700',
              height: '48px',
              textTransform: 'uppercase',
              backgroundColor: '#ffffff',
              color: '#000051'
            }}>
          Send OTP
        </button>
        }
        {!verificationStatus && verificationOtpSent &&
        <button type="button" onClick={handleVerifyOTP} className="w-100" style={{
              border: 'none', borderRadius: '5px',
              fontSize: '1em',
              fontWeight: '700',
              height: '48px',
              textTransform: 'uppercase',
              backgroundColor: '#ffffff',
              color: '#000051'
            }}>
          Verify OTP
        </button>
        }
      </form>
    {Boolean(process) &&
          <div className="loaderReturn" style={{ zIndex: '99' }}>
            <img
              src={'/Images/LandingPage_img/loader1.gif'}
              style={{ width: "100%", }}
            />
          </div>
        }
        
    </div>
  );
};



const Kyc2 = ({ user }) => {
  const history = useHistory()
  
console.log('kyc user', user);
  const [frontLoaded, setfrontLoaded] = useState(null)
  const [backLoaded, setbackLoaded] = useState(null)
  const [Name, setName] = useState()
  const [Email, setEmail] = useState()
  const [Number, setNumber] = useState()
  const [DOB, setDob] = useState()
  const [process, setProcess] = useState(false);
  
  const [offlineAdharShow, setOfflineAdharShow] = useState(true);
  const [onlineAdharShow, setOnlineAdharShow] = useState(false);

const activeOnline = ()=>{
    setOnlineAdharShow(true);
    setOfflineAdharShow(false);
}

const activeOffline = ()=>{
    setOnlineAdharShow(false);
    setOfflineAdharShow(true);
}
  let aadharProcess = useRef(false);

  const handleSubmitdata = (e) => {

    if (user.verified == "unverified" || user.verified == undefined || user.verified == "" ) {

      if (aadharProcess.current === false) {
        setProcess(true);
        aadharProcess.current = true;
        e.preventDefault();
        console.log(frontLoaded, backLoaded)
        const formData = new FormData();

        formData.append("Name", Name);
        formData.append("Email", Email);
        formData.append("Number", Number);
        formData.append("DOB", DOB);
        formData.append("front", frontLoaded);
        formData.append("back", backLoaded);

        if (frontLoaded && backLoaded) {
          const access_token = localStorage.getItem('token')
          const headers = {
            Authorization: `Bearer ${access_token}`,
          }

          axios.post(baseUrl+`aadharcard`, formData, { headers })
            .then((res) => {
              console.log(res.data);
              if (res.data.msg === false) {
                Swal.fire({
                  title: "Duplicate Entity",
                  icon: "danger",
                  confirmButtonText: "error",
                });
              }
              else {
                Swal.fire({
                  title: "You Kyc form submitted",
                  icon: "success",
                  confirmButtonText: "ok",
                });
                history.push("/Profile")
              }
              // console.log(res.data)
              //
              aadharProcess.current = false;
              setProcess(false)
            }).catch((e) => {
              console.log(e);
              if (e.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('token');
                window.location.reload()
                history.push("/login")
              }
            })
        }
        else {
          aadharProcess.current = false;
          setProcess(false)
          alert('please fill all field')
        }
      }
      else {
        alert('You have submited request already.')
      }
    }
    else {
      Swal.fire({
          title: "Your AadharKYC already verified Or Under Proccess",
          icon: "success",
          confirmButtonText: "ok",
        });
        history.push("/Profile")
    }
  }
  
  useEffect(() => {
    console.log(user)
    let access_token = localStorage.getItem('token');
    access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload()
      history.push("/login");
    }
    const frontPhoto = document.getElementById('frontPhoto');
    if(frontPhoto){
        frontPhoto.onchange = e => {
          const [file] = frontPhoto.files;
          setfrontLoaded(file)
        }   
    }
    const backPhoto = document.getElementById('backPhoto');
    
    if(backPhoto){
        backPhoto.onchange = e => {
          const [file] = backPhoto.files;
          setbackLoaded(file)
        }        
    }

  }, [])
  return (
    <div>
      <Header user={user} />
      <div className="leftContainer">
      {
          onlineAdharShow &&
      
       <button type="button" onClick={activeOnline}  className="w-50" style={{
              border: 'none', borderRadius: '0px',
              float: 'right',
              fontSize: '1em',
              fontWeight: '700',
              textTransform: 'uppercase',
              background: 'var(--main-bg)',
              color: '#ffffff',
              border:'1px solid white',
              marginBottom: '10px',
              marginLeft: '10px',
              padding: '16px'
            }}>
            Online AadharKYC
        </button>
      }
        
        {/* <button type="button" onClick={activeOffline}  className="offlinekycbtn" style={{
              border: 'none', borderRadius: '50px',
              fontSize: '1em',
              fontWeight: '700',
              textTransform: 'uppercase',
              background: 'blue',
              color: '#ffffff',
              marginLeft: '10px !important',
              // margin: '10px',
              padding: '16px',
              border:'1px solid white',
              margin:'',
            }}>
            Offline AadharKYC
        </button> */}


<button type="button" onClick={activeOffline}  className="offlinekycbtn" style={{
              border: 'none', borderRadius: '50px',
              fontSize: '1em',
              display:'inline-block',
              fontWeight: '700',
              textTransform: 'uppercase',
              background: 'skyblue',
              color: '#ffffff',
              marginLeft: '10px !important',
          
              
         
              padding: '16px',
              border:'1px solid white',
              
            }}>
            Offline AadharKYC
        </button>
      
      {onlineAdharShow &&
        <div className="kyc_page_2 kycPage  py-4 px-3">
        <AadharVerificationForm />
        </div>
      }
        
       {offlineAdharShow &&
            
        <div className="kyc_page_2 kycPage  py-4 px-3">
          <p className="kyc_page_2">
            You need to submit a document that shows that you are{" "}
            <span style={{ fontWeight: 500 }}>above 18 years</span> of age and not a
            resident of{" "}
            <span style={{ fontWeight: 500 }}>
              Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh, Tamil Nadu and
              Karnataka.
            </span>
          </p>
          {/* <br /> */}
          {/* <div>
            <span style={{ fontSize: "1.5em" }}>Step 2</span> of 3
          </div> */}
          <p className="mt-2 kyc_page_2_title">
            Enter details of Aadhar Card:{" "}
            {/* <span style={{ fontWeight: 500 }}>098765432212</span> */}
          </p>
          {/* <br /> */}
          <form onSubmit={handleSubmitdata}>

          <div style={{ marginTop: "10px" }}>
            <div className="kyc-doc-input">
              <div className="label">Enter Your First Name</div>
              <input type="text"
                name="Name"
                placeholder='Enter name'
                value={Name}
                onChange={(e) => setName(e.target.value)} required
              />
            </div>
            {/* <br /> */}
            <div className="kyc-doc-input mt-4">
              <div className="label">Enter Your Email Id</div>
              <input type="text"
                name="Email"
                placeholder='Email Id'
                value={Email}
                onChange={(e) => setEmail(e.target.value)} required
              />
            </div>
            <div className="kyc-doc-input mt-4">
              <div className="label">Enter Your Date of Birth</div>
              <input type="date"
                name="Name"
                placeholder='enter name'
                value={DOB}
                onChange={(e) => setDob(e.target.value)} required
              />
            </div>
            <div className="kyc-doc-input mt-4">
              <div className="label">Enter Your Aadhar Number</div>
              <input type="tel"
                name="Name"
                placeholder=' Aadhar Number'
                onChange={(e) => setNumber(e.target.value)} required
              />
            </div>

            <div className="aadhar_title">
                <p>Upload Your Aadhar ID</p>
            </div>

            <div className="id_uploader">
              <div className={`${css.doc_upload} mt-4`}>
                <input id="frontPhoto" name="frontPhoto" type="file" accept="image/*" required />
                {!frontLoaded && <div className="cxy flex-column position-absolute ">
                  <img src="/Images/file-uploader-icon.png" width="17px" alt="" className="snip-img" />
                  <div className={`${css.sideNav_text} mt-2`}>
                    Upload Front Copy
                  </div>
                </div>}
                {frontLoaded && <div className={css.uploaded}>
                  <img src="/Images/file-icon.png" width="26px" alt="" style={{ marginRight: '20px' }} />
                  <div className="d-flex flex-column" style={{ width: '80%' }}>
                    <div className={css.name}>{frontLoaded.name}</div>
                    <div className={css.size}>{(frontLoaded.size / 1024 / 1024).toFixed(2)} MB</div>
                  </div>
                  <div className="image-block">
                    <img src="/Images/global-cross.png" width="10px" alt="" onClick={() => setfrontLoaded(null)} />
                  </div>
                </div>}
              </div>
              <div className={`${css.doc_upload} mt-4`}>
                <input id="backPhoto" name="backPhoto" type="file" accept="image/*" required />
                {!backLoaded && <div className="cxy flex-column position-absolute ">
                  <img src="/Images/file-uploader-icon.png" width="17px" alt="" className="snip-img" />
                  <div className={`${css.sideNav_text} mt-2`}>
                    Upload Back Copy
                  </div>
                </div>}
                {backLoaded && <div className={css.uploaded}>
                  <img src="/Images/file-icon.png" width="26px" alt="" style={{ marginRight: '20px' }} />
                  <div className="d-flex flex-column" style={{ width: '80%' }}>
                    <div className={css.name}>{backLoaded.name}</div>
                    <div className={css.size}>{(backLoaded.size / 1024 / 1024).toFixed(2)} MB</div>
                  </div>
                  <div className="image-block">
                    <img src="/Images/global-cross.png" width="10px" alt="" onClick={() => setbackLoaded(null)} />
                  </div>
                </div>}
              </div>
            </div>



            {/* <div style={{ marginTop: "50px" }}>
              <span style={{ color: "rgb(149, 149, 149)", fontWeight: 500 }}>
                State
              </span>
              <div className="kyc-input mt-2">
                <div className="kyc-input-text">Select State</div>
                <div className="arrow cxy">
                  <img
                    src="/images/global-black-chevronDown.png"
                    width="15px"
                    alt=""
                  />
                </div>
              </div>
            </div> */}
            
          </div>
          <div className="refer-footer p-4">
            {/* <button className="refer-button btn-success mr-2  w-100 " style={{ background: '#6c757d !important' }}>
              <a href="/kyc">Previous</a>
            </button> */}
            <button type="submit" className="w-100" style={{
              border: 'none', borderRadius: '5px',
              fontSize: '1em',
              fontWeight: '700',
              height: '48px',
              textTransform: 'uppercase',
              backgroundColor: 'rgb(138 178 21)',
              color: '#000051'
            }}>
              {/* <Link  >Next</Link> */}
              submit
            </button>
            
          </div>
          </form>
        </div>
       }
       
        {Boolean(process) &&
          <div className="loaderReturn" style={{ zIndex: '99' }}>
            <img
              src={'/Images/LandingPage_img/loader1.gif'}
              style={{ width: "100%", }}
            />
          </div>
        }
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Kyc2;



// background: linear-gradient(45deg, #ffffff, #8a8383);