import React, { useEffect, useState } from "react";
import axios from "axios";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";

export default function RunningCardStatic({runningGames}) {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const publicLocalUrl = process.env.REACT_APP_BACKEND_LOCAL_URL;
  const publicLiveUrl = process.env.REACT_APP_BACKEND_LIVE_URL;
  var publicUrl;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
    publicUrl = publicLocalUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
    publicUrl = publicLiveUrl;
  }

  return (
    <>
      {runningGames && runningGames.map((runnig) => {
        return (
          <div className={`${css.betCard} mt-1 card`}>
            <div className={`${css.betcard_user_info}`}>
              <div className={`${css.betCard_user_info}`}>
                PLAYING FOR
                <i
                  className="fa-solid fa-indian-rupee-sign"
                  style={{ paddingLeft: "0.3rem" }}
                ></i>
                {runnig?.Game_Amount / 2}
              </div>
              <div className="mid"></div>
              <div className={`${css.betCard_user_info}`}>
                PRIZE
                <i
                  className="fa-solid fa-indian-rupee-sign"
                  style={{ paddingLeft: "0.3rem" }}
                ></i>
                {runnig?.winnAmount}
              </div>
            </div>

            <div className={`${css.betcard_user_set}`}>
              <div>
                <div className="" style={{ marginBottom: "0.5rem" }}>
                  <img
                      src={`https://rkludo.in/user.png`}
                      alt=""
                      width="35px"
                      height="35px"
                      style={{
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                      }}
                    />
                </div>
                <div style={{ lineHeight: 1 }} className="text-center">
                  <span className={css.betCard_playerName}>
                    {runnig?.Creator}
                  </span>
                </div>
              </div>

              <div className="mid">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/Images/kd_ludo/versus.png"}
                    alt=""
                    width="60px"
                  />
                </div>
              </div>

              <div>
                <div className="text-center" style={{ marginBottom: "0.5rem" }}>
                  <img
                      src={`https://rkludo.in/user.png`}
                      alt=""
                      width="35px"
                      height="35px"
                      style={{
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                      }}
                    />
                </div>
                <div style={{ lineHeight: 1 }}>
                  <span className={css.betCard_playerName}>
                    {runnig?.Acceptor}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      ;
    </>
  );
}
