import React, { useEffect, useState, useRef } from "react";
import "../css/viewGame1.css";
import "../css/layout.css";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Components/Header";
import css from "../css/Pan.module.css";
//import Rightcontainer from '../Components/Rightcontainer';
import Swal from "sweetalert2";
import "../css/Loader.css";

export default function ViewGame1(props) {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const beckendLocalSocketUrl = process.env.REACT_APP_BACKEND_LOCAL_SOCKET;
  const beckendLiveSocketUrl = process.env.REACT_APP_BACKEND_LIVE_SOCKET;

  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
    var socketURL = beckendLocalSocketUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
    socketURL = beckendLiveSocketUrl;
  }

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [Game, setGame] = useState();
  const [status, setStatus] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState(""); // ADDED BY TEAM

  const [reason, setReason] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [socket, setSocket] = useState();
  const [roomcode, setRoomcode] = useState("");
  let submitReq = useRef(false);
  const isMounted = useRef(true);

  const [submitProcess, setProcess] = useState(true);

  const getPost = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const response = await axios.patch(
        `${baseUrl}challange/roomcode/${path}`,
        { Room_code: roomcode },
        { headers }
      );
      console.log("response.status: ", response.status);
      if (response.status === 200) {
        const data = response.data;
        if (typeof data === "string") {
          alert(data); // Show alert with the message from the server
        } else {
          setGame(data);
          //socket.emit("challengeOngoing");
          socket.emit("setRoomCode");
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/login");
      } else {
        alert("Something went wrong. Please try again.");
      }
    }
  };

  /// user details start

  const [user, setUser] = useState();
  const [userAllData, setUserAllData] = useState();
  //console.log("user Data: ", user)
  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        console.log("res ", res);
        setUser(res.data._id);
        setUserAllData(res.data);
        // // console.log(res.data._id)
        Allgames(res.data._id);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  /// user details end

  const [ALL, setALL] = useState();

  const Allgames = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axios
      .get(baseUrl + `challange/${path}`, { headers })
      .then((res) => {
        if (res.data.Status == "new" || res.data.Status == "requested") {
          setTimeout(async () => {
            await axios
              .get(baseUrl + `challange/${path}`, { headers })
              .then((res) => {
                if (
                  res.data.Status == "new" ||
                  res.data.Status == "requested"
                ) {
                  history.push(props.location.state.prevPath);
                } else {
                  setProcess(false);
                }
              })
              .catch((error) => {
                console.error(error);
                history.push(props.location.state.prevPath);
              });
          }, 10000);
        } else {
          setProcess(false);
        }
        setALL(res.data);
        setGame(res.data);
        // // console.log(res.data.accepted_by._id)
        if (userId == res.data.accepted_by._id)
          setFecthStatus(res.data.Acceptor_status);

        if (userId == res.data.creator._id)
          setFecthStatus(res.data.Creator_Status);

        //console.log("setFecthStatus", res.data.Creator_Status);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  const [timer, setTimer] = useState(0); // Initial timer value in seconds

  // console.log("timer: ", timer);
  // useEffect(() => {

  //   const intervalId = setInterval(async () => {
  //     try {

  //       const access_token = localStorage.getItem('token');
  //       const headers = {
  //         Authorization: `Bearer ${access_token}`
  //       };

  //       const response = await axios.get(baseUrl + `challange/${path}`, { headers });
  //       const { data } = response;
  //       if (data.Accepetd_By == user) {

  //         if (data.Room_code == 0) {
  //           setTimer((prevTimer) => {
  //             if (prevTimer === 0) {
  //               clearInterval(intervalId);
  //               window.location.reload();
  //               return 0;
  //             }
  //             return prevTimer - 1;
  //           });
  //         }
  //         // If roomcode is not 0, simply decrement the timer without reloading
  //         else {
  //           setTimer((prevTimer) => {
  //             if (prevTimer === 0) {
  //               clearInterval(intervalId);
  //               // window.location.reload();
  //               return 0;
  //             }
  //             Allgames(user)
  //             clearInterval(intervalId);
  //             return 0;
  //           });
  //         }

  //       } else {
  //         clearInterval(intervalId); // Clear the interval if conditions aren't met
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       clearInterval(intervalId); // Clear the interval in case of an error
  //     }
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [user]);

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };
    let socket = new WebSocket(socketURL);
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socket._socketListeners[event])
            socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };
      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });
      socket.listen("updateRoomCode", () => {
        //console.log("updateRoomCode: ");
        Allgames(user);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);
          socket = new WebSocket(socketURL);
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();
    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    // console.log(history.location)

    role();
  }, []);

  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
    setStatus(null);
  };

  // Result

  const Result = async (e) => {
    e.preventDefault();
    if (submitReq.current == false) {
      submitReq.current = true;
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (status) {
        setProcess(true);
        const formData = new FormData();
        formData.append("file", scrnshot);
        formData.append("status", status);
        if (status == "cancelled") {
          formData.append("reason", selectedOption);
        }

        await axios({
          method: "post",
          url: baseUrl + `challange/result/${path}`,
          data: formData,
          headers: headers,
        })
          .then((res) => {
            socket.emit("resultAPI");
            submitReq.current = false;
            setProcess(false);
            history.push(props.location.state.prevPath);
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              history.push("/login");
            }
          });
      } else {
        submitReq.current = false;
        alert("please fill all field or Re-Select result status");
      }
    }
  };

  const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(Game.Room_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const Completionist = () => <span>You are good to go!</span>;

  // ADDED BY TEAM
  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  // ADDED BY TEAM

  const handleRoomCodeChange = (e) => {
    const value = e.target.value;
    if (value.length <= 8) {
      setRoomcode(value);
    }
  };
  console.log("GAME: ", Game);
  return (
    <>
      <Header user={userAllData && userAllData} />
      {/* {!Game && <div className="lds-ripple"><div></div><div></div></div>} */}
      {Game && (
        <div className="leftContainer">
          <div className="main-area">
            {!Boolean(submitProcess) && (
              <div className="battleCard-bg">
                <div className="battleCard">
                  <div className="players cxy pt-2">
                    <div className="flex-column cxy">
                      <h5>{Game.Created_by && Game.Created_by.Name}</h5>
                      {Game.Created_by.avatar ? (
                        <img
                          src={baseUrl + `${Game.Created_by.avatar}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = process.env.PUBLIC_URL + "/user.png";
                          }}
                          width="50px"
                          height="50px"
                          alt=""
                          style={{
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                          }}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width="50px"
                          height="50px"
                          alt=""
                          style={{
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                    <img
                      className="mx-3"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/versus.png"
                      }
                      width="23px"
                      alt=""
                    />
                    <div className="flex-column cxy ">
                      <h5> {Game.Accepetd_By && Game.Accepetd_By.Name}</h5>
                      {Game.Accepetd_By.avatar ? (
                        <img
                          src={baseUrl + `${Game.Accepetd_By.avatar}`}
                          width="50px"
                          height="50px"
                          alt=""
                          style={{
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                          }}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width="50px"
                          height="50px"
                          alt=""
                          style={{
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="amount cxy mt-2">
                    <span style={{ opacity: "0.8" }}>Playing for</span>
                    <img
                      className="mx-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/global-rupeeIcon.gif"
                      }
                      width="25x"
                      alt=""
                    />
                    <span
                      style={{
                        fontSize: "1.2em",
                        fontWeight: 700,
                        opacity: "0.8",
                      }}
                    >
                      {Game.Game_Ammount}
                    </span>
                  </div>
                  <div className="thin-divider-x my-3" />

                  {(Game.Room_code == null && (
                    <div className="roomCode cxy flex-column">
                      Waiting for Room Code...
                      <h6>रूम कोड का इंतजार है।</h6>
                      <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )) ||
                    (Game.Room_code != 0 && (
                      <div>
                        <div className="roomCode cxy flex-column">
                          <div className="text-center">
                            <div>Room Code</div>
                            <span>{Game.Room_code}</span>
                          </div>
                          <button
                            className="roomcode_btn"
                            onClick={(e) => copyCode(e)}
                          >
                            Copy Code
                          </button>
                        </div>
                      </div>
                    )) ||
                    (Game.Room_code == 0 &&
                      ((Game.creator._id == user && (
                        <div>
                          {Game.Game_type == "LudoManual" && (
                            <div className="ludomanual_container">
                              सभी player ध्यान दे ! आपको RoomCode ClASSIC के
                              देने है जो भी POPULAR का RoomCode देगा उसका
                              payment रोक लिया जायेगा!
                            </div>
                          )}
                          <div className="roomCode cxy flex-column">
                            Set Room Code
                            <h6>
                              आप लूडो किंग पर जाए और रूम कोड create करे, उस कोड
                              को Set A Room code मै Enter करे
                            </h6>
                            <input
                              type="number"
                              className="mt-1 w-75"
                              value={roomcode}
                              onChange={handleRoomCodeChange}
                              minLength={8}
                              maxLength={8}
                              placeholder="Enter 8-character room code"
                            />
                            <button
                              className="roomcode_btn mt-2"
                              type="button"
                              onClick={() => getPost()}
                            >
                              SET ROOM CODE
                            </button>
                          </div>
                        </div>
                      )) ||
                        (Game.accepted_by._id == user && (
                          <div>
                            {Game.Game_type == "Ludo Classics" && (
                              <div className="cxy flex-column">
                                <div
                                  className=" bg-danger my-2"
                                  style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <span className="text-white">
                                    किसी भी प्लेयर को पॉपुलर का रूम कोड मिल गया
                                    है, वो स्क्रीनशॉट ले और अपना गेम लेफ्ट करे।
                                    हमारे साथ शेयर करें।
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="roomCode cxy flex-column">
                              Waiting for Room Code
                              <h6>
                                आपका रूम कोड जेनरेट हो रहा है कृपया 1 मिनट तक
                                इंतजार करे।
                              </h6>
                              {timer != 0 && (
                                <p style={{ color: "red" }}>
                                  Timer: {timer} seconds
                                </p>
                              )}
                              <div className="lds-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        ))))}

                  {/* <div className='thin-divider-x my-3' /> */}
                  <div className="rules">
                    <span className="rules-title cxy mb-1">Game Rules</span>
                    <ol className="list-group list-group-numbered">
                      <li className="rules_list_group_items">
                        Record every game while playing.
                      </li>
                      <li className="rules_list_group_items">
                        For cancellation of game, video proof is necessary.
                      </li>
                      <li className="rules_list_group_items">
                        <h6>
                          ◉ महत्वपूर्ण नोट:कृपया गलत गेम परिणाम अपलोड न करें,
                          अन्यथा आपके वॉलेट बैलेंस पर penalty लगाई जायगी। गलत
                          रिजल्ट अपडेट करने पर 50 रुपये पेनल्टी लगेगी।
                        </h6>
                      </li>
                      <li className="rules_list_group_items">
                        <h6 className="">
                          महत्वपूर्ण नोट: यदि आप गेम के परिणामों को अपडेट नहीं
                          करते हैं, तो आपके वॉलेट बैलेंस पर जुर्माना लगाया
                          जाएगा। रिजल्ट अपडेट नहीं करने पर 25 रुपये पेनल्टी
                          लगेगी।
                        </h6>
                      </li>
                    </ol>
                  </div>
                  <div className="match-status-border">
                    <div>Match Status</div>
                  </div>
                  <form
                    className="result-area"
                    onSubmit={(e) => {
                      Result(e);
                    }}
                    encType="multipart/form-data"
                  >
                    {fecthStatus !== null && fecthStatus !== undefined && (
                      <p>
                        You have already updated your battle result for{" "}
                        <h6 className="d-inline-block text-uppercase">
                          <b>{fecthStatus}</b>
                        </h6>
                      </p>
                    )}
                    {fecthStatus == null && fecthStatus == undefined && (
                      <>
                        {" "}
                        <p>
                          After completion of your game, select the status of
                          the game and post your screenshot below.
                        </p>
                        <div
                          className="MuiFormGroup-root radios"
                          role="radiogroup"
                          aria-label="Result"
                        >
                          <label className="MuiFormControlLabel-root hidden Mui-disabled">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="select"
                                  defaultChecked
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              (Disabled option)
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8"
                              aria-disabled="false"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="winn"
                                  onClick={(e) => setStatus("winn")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              I Won
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
                              aria-disabled="false"
                              root="[object Object]"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="lose"
                                  onClick={(e) => setStatus("lose")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              I Lost
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root"
                              aria-disabled="false"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="cancelled"
                                  onClick={(e) => setStatus("cancelled")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              Cancel
                            </span>
                          </label>
                        </div>
                      </>
                    )}
                    {status !== null &&
                      status !== "cancelled" &&
                      status !== "lose" && (
                        <div className={`${css.doc_upload} mt-5`}>
                          {/* <input type="file" onChange={(e) => setScrnshot(e.target.files[0])} accept="image/*" required /> */}
                          {/* ADDED BY TEAM */}
                          <input
                            type="file"
                            onChange={handleChange}
                            accept="image/*"
                            required
                          />
                          {/* ADDED BY TEAM */}
                          {!scrnshot && (
                            <div className="cxy flex-column position-absolute ">
                              <i className="fa-solid fa-arrow-up"></i>
                              {/* <img src={process.env.PUBLIC_URL + '/Images/upload_icon.png'} width="17px" alt="" className="snip-img" /> */}
                              <div className={`${css.sideNav_text} mt-2 `}>
                                Upload screenshot.
                              </div>
                            </div>
                          )}
                          {scrnshot && (
                            <div className={css.uploaded}>
                              <img
                                src="/images/file-icon.png"
                                width="26px"
                                alt=""
                                style={{ marginRight: "20px" }}
                              />
                              <div
                                className="d-flex flex-column"
                                style={{ width: "80%" }}
                              >
                                <div className={`${css.name} `}>
                                  {scrnshot.name}
                                </div>
                                <div className={css.size}>
                                  {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                                </div>
                              </div>
                              <div className="image-block">
                                <img
                                  src="/images/global-cross.png"
                                  width="10px"
                                  alt=""
                                  onClick={clearImage}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {status !== null && status == "cancelled" && (
                      <div className="form-group">
                        {/*
                   <textarea className="form-control border-secondary" name="" id="" rows="3" onChange={(e) => { setReason(e.target.value) }} required></textarea>
                 */}
                        <select
                          className="form-control border-secondary"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="">Select Cancel Option</option>
                          <option value="No Room Code">No Room Code</option>
                          <option value="Not Joined">Not Joined</option>
                          <option value="Not Playing">Not Playing</option>
                          <option value="Dont Want to Play">
                            Dont Want to Play
                          </option>
                          <option value="Opponent Abusing">
                            Opponent Abusing
                          </option>
                          <option value="Game Not Start">Game Not Start</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    )}

                    {/* ADDED BY TEAM */}
                    <div style={{ width: "100%" }}>
                      <img src={scrnshot1} style={{ width: "100%" }} />
                    </div>
                    {/* ADDED BY TEAM */}

                    {/* <button type='submit' className='btn btn-danger mt-3 text-white' id="post" onSubmit={(e) => { e.preventDefault(); Result() }}>Post Result</button> */}
                    {fecthStatus == null && fecthStatus == undefined && (
                      <input
                        type="submit"
                        className="result_submit_btn"
                        id="post"
                      />
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Boolean(submitProcess) && (
        <div
          className="loaderReturn"
          style={{ zIndex: "99", minHeight: "100vh" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/Images/LandingPage_img/loader1.gif"}
            style={{ width: "100%" }}
          />
        </div>
      )}
      <div className="divider-y"></div>
    </>
  );
}
